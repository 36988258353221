<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import assignEmployeesModal from "./components/assignEmployeesModal";
/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Project Overview",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, assignEmployeesModal },
  data() {
    return {
      // title: "Project Overview",
      uuid: "",
      items: [
        {
          text: "Ordres de maintenance",
          href: "/",
        },
      ],

      maintenanceOrderData: {
        id: 0,
        uuid: "",
        ref: "",
        description: "",
        createdBy: "",
        createdAt: "",
        maintenanceRequestData: [],
        start_date: "",
        planned_end_date: "",
        planned_end_times: "",
        status: "",
        hasAssignedEmployees: "",
        execution_type: "",
        supplier_id: "",
        assignedEmployees: [],
        supplierHasTerminatedTheTask: "",
      },
    };
  },

  mounted() {
    this.uuid = this.$route.params.uid;
    this.fetchSingleMaintenanceOrderData();
  },

  methods: {
    fetchSingleMaintenanceOrderData() {
      var _this = this;
      this.$http
        .post("/ops/maintenance_orders/getSingleOrderData/" + this.uuid)

        .then((res) => {
          var status = res.data.original.status;
          console.log(res);
          var mo = res.data.original.mo;
          switch (status) {
            case 200:
              _this.maintenanceOrderData.id = mo.id;
              _this.maintenanceOrderData.ref = mo.ref;
              _this.maintenanceOrderData.description = mo.description;
              _this.maintenanceOrderData.createdBy = mo.createdBy;
              _this.maintenanceOrderData.createdAt = mo.createdAt;
              _this.maintenanceOrderData.maintenanceRequestData =
                mo.maintenanceRequestData;
              _this.maintenanceOrderData.start_date = mo.start_date;
              _this.maintenanceOrderData.planned_end_date = mo.planned_end_date;
              _this.maintenanceOrderData.planned_end_times =
                mo.planned_end_times;
              _this.maintenanceOrderData.status = mo.status;
              _this.maintenanceOrderData.hasAssignedEmployees =
                mo.hasAssignedEmployees;
              _this.maintenanceOrderData.execution_type = mo.execution_type;
              _this.maintenanceOrderData.supplier_id = mo.supplier_id;
              _this.maintenanceOrderData.assignedEmployees = mo.assignedEmployees;
              _this.maintenanceOrderData.supplierHasTerminatedTheTask = mo.supplierHasTerminatedTheTask;
              break;

            case 500:
              Swal.fire("Avertissement!", res.data.original.msg, "warning");
              break;
          }
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="''" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="button-items">
                
                <b-button variant="success" v-b-modal.approvalModal>
                  <i class="bx bx-user font-size-16 align-middle mr-2"></i>
                  Affecter des employés
                </b-button>
                <b-button variant="success" v-b-modal.approvalModal>
                  <i
                    class="bx bx-check-double font-size-16 align-middle mr-2"
                  ></i>
                  Intervention Terminée
                </b-button>
                <b-button variant="primary" v-b-modal.approvalModal>
                  <i class="bx bx-shield font-size-16 align-middle mr-2"></i>
                  Evaluer l'intervention
                </b-button>
              </div>
            </div>
            <h4 class="card-title">
              {{ maintenanceOrderData.ref }}
            </h4>

            <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted"
            >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Détails</span>
                </template>
                <div class="row">
                  <div class="col-xl-12">
                    <a
                      @click="fetchSingleMaintenanceOrderData()"
                      href="javascript:void()"
                      ><h1>
                        <i
                          class="bx bx-rotate-right"
                          style="float: right;"
                        ></i></h1
                    ></a>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="table-responsive mb-0">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th>Demandeur :</th>
                                <td>
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .requestedBy
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Type de maintenance :</th>
                                <td>
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .type
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Lieu de maintenance :</th>
                                <td>
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .location
                                  }}
                                  /
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .locationRec
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Urgence :</th>
                                <td>
                                  {{
                                    maintenanceOrderData.maintenanceRequestData
                                      .urgency
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <th>Source :</th>

                                <td>
                                  <router-link
                                    :to="{
                                      name: 'requests.maintenance.display',
                                      params: {
                                        uid:
                                          maintenanceOrderData
                                            .maintenanceRequestData.uuid,
                                      },
                                    }"
                                    >Requête de maintenance</router-link
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="table-responsive mb-0">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th>Date de création :</th>
                                <td>
                                  {{ maintenanceOrderData.createdAt }}
                                </td>
                              </tr>
                              <tr>
                                <th>Ordre créer par :</th>
                                <td>
                                  {{ maintenanceOrderData.createdBy }}
                                </td>
                              </tr>
                              <tr>
                                <th>Date début d'intervention :</th>
                                <td>
                                  {{ maintenanceOrderData.start_date }}
                                </td>
                              </tr>
                              <tr>
                                <th>Date fin d'intervention prévue :</th>
                                <td>
                                  {{ maintenanceOrderData.planned_end_date }}
                                  {{ maintenanceOrderData.planned_end_times }}
                                </td>
                              </tr>
                              <tr>
                                <th>Etat :</th>
                                <td>
                                  <span
                                    v-html="maintenanceOrderData.status"
                                  ></span>
                                </td>
                              </tr>

                              <tr>
                                <th>Etat d'exécution :</th>
                                <td>
                                  <span
                                    v-html="
                                      maintenanceOrderData.executionStatus
                                    "
                                  ></span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h4 class="card-title mb-4">Détails</h4>
                        <p lass="text-muted mb-4">
                          {{ maintenanceOrderData.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Intervenants</span>
                </template>

                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                    <table class="table mb-0 table-bordered">
                        <thead>
                            <tr>
                                <th>Matricule</th>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Fonction</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="employee in maintenanceOrderData.assignedEmployees" v-bind:key="employee.id">
                                <td>{{ employee.id_number }}</td>
                                <td>{{ employee.first_name }}</td>
                                <td>{{ employee.last_name }}</td>
                                <td>{{ employee.function }}</td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
                    </div>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Evaluation</span>
                </template>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Rapport</span>
                </template>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <assign-employees-modal
      :moId="maintenanceOrderData.id"
      :moExecutionType="maintenanceOrderData.execution_type"
      :supplierId="maintenanceOrderData.supplier_id"
    />
  </Layout>
</template>
